import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "E-Mail-Einrichtung",
  "description": "Legen Sie die Absendeadresse fest, über die das Portal mit Benutzern kommuniziert.",
  "author": "Jochen Ritscher",
  "categories": ["settings"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Um die Absender-Adresse anzupassen klicken Sie im Karriereportal Backend auf Einstellungen -> Mandant -> Bearbeiten`}</p>
    <p>{`Hier geben Sie die E-Mail-Adresse ein, die Absendeadresse sein soll. Bitte stellen Sie sicher dass die E-Mail-Adresse Ihre Haupt-Domain enthält und ein erreichbares Postfach ist.
z.b. `}<a parentName="p" {...{
        "href": "mailto:karriere@ihrunternehmen.de"
      }}>{`karriere@ihrunternehmen.de`}</a></p>
    <p>{`Bitte achten Sie darauf, dass Sie durch einen sog. SPF-Eintrag die Wahrscheinlichkeit verringern können, dass automatisierte E-Mails vom inriva Karriereportal beim Empfänger als Spam aussortiert werden.`}</p>
    <h2>{`Wie setze ich einen SPF-Eintrag?`}</h2>
    <p>{`Das SPF (Sender Policy Framework) ist ein zur Sender-Authentifizierung genutztes Spamschutz-Verfahren.
In den DNS-Einträgen einer Domain werden zusätzliche Informationen in Form von SPF-Records gespeichert.
Diese zusätzlichen SPF-Einträge im DNS beinhalten detaillierte Angaben zu den versendenden Mailservern.`}</p>
    <p>{`Der empfangende Mailserver hat über den SPF-record der Domain die Möglichkeit zu prüfen, ob die erhaltene E-Mail von
einem authorisierten Mailserver stammt, oder von einem nicht authorisierten Server.
Im letzten Fall kann die Email über den SPF Spamschutz identifiziert und als SPAM deklariert werden.`}</p>
    <p>{`Hierfür setzen Sie bitte einen TXT-Eintrag. Der Wert muss ohne Anführungszeichen angegeben werden.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://technet.microsoft.com/de-de/library/dn789058(v=exchg.150).aspx"
        }}>{`Anleitung für Microsoft Office 365`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.df.eu/de/support/df-faq/e-mail/spf-sender-policy-framework/spf-eintraege/"
        }}>{`Anleitung für Domainfactory`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.checkdomain.de/support/nameserver-dns/nutzung-einrichtung-dns/spf-eintrag/"
        }}>{`Anleitung für Checkdomain`}</a></li>
    </ul>
    <h2>{`Beispiel für Office 365:`}</h2>
    <pre {...{
      "className": "shiki-unknown"
    }}><code parentName="pre">{`v=spf1 +a +mx ip4:[Externe IP-Adresse Ihres Mailservers] include:spf.protection.outlook.com include:inriva.com -all`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      